import ApiService from "@/core/services/api.service";

var Role = {
    list: function(searchData) {
        return ApiService.post("/role/list", searchData);
    },
    save: function(currAccount) {
        return ApiService.post("/role/save", currAccount);
    },
    del: function(id) {
        return ApiService.get("/role/del?id=" + id);
    },
    findById: function(id) {
        return ApiService.get("/role/findById?id=" + id);
    },
    getResList: function(id) {
        return ApiService.get("/role/list-res?roleId=" + id);
    },
    saveResList: function(roleId, resIdList) {
        return ApiService.post("/role/save-res", {roleId, resIdList});
    }
}

export default Role;