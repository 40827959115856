<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <b-container fluid style="padding-right: 12.5px; padding-left: 12.5px">
        <b-row class="my-1">
          <b-col sm="3">
            <b-row class="my-1">
              <label>角色名查询:</label>
            </b-row>
            <b-row class="my-1">
              <b-form-input
                v-model="search.name"
                placeholder="请输入角色名"
              ></b-form-input>
            </b-row>
          </b-col>

          <b-col sm="3">
            <b-row class="my-1">
              <label style="color: white">.</label>
            </b-row>
            <b-row class="my-1">
              <b-button
                variant="primary"
                @click="role2page()"
                style="margin-right: 10px"
                >搜 索</b-button
              >
              <b-button variant="success" @click.prevent="role2initAdd()"
                >新增角色</b-button
              >
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-vertical-center">
          <thead>
            <tr>
              <th class="pl-0 text-center" style="width: 50px">#</th>
              <th class="pl-0" style="min-width: 100px">名称</th>
              <th class="pl-0" style="min-width: 100px">所属机构</th>
              <th class="pl-0" style="min-width: 100px">授权菜单</th>
              <th class="pl-0" style="min-width: 150px">操作</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in roleList">
              <tr v-bind:key="i">
                <!-- 序号 -->
                <td class="pl-0 text-center">
                  {{ i + 1 }}
                </td>

                <!-- 名称 -->
                <td class="pl-0">
                  <a
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >
                    {{ item.name }}
                  </a>
                </td>

                <!-- 所属机构 -->
                <td class="pl-0">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.orgzName }}
                  </span>
                </td>

                <!-- 授权菜单 -->
                <td class="pl-0">
                  <a 
                    class="btn btn-icon btn-light btn-sm mx-3"
                    title="菜单"
                    @click.prevent="editResInit(item.id)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg
                        title="菜单"
                        src="media/svg/icons/Text/Bullet-list.svg"
                      ></inline-svg>
                    </span>
                  </a>
                </td>

                <!-- 操作 -->
                <td class="pl-0">
                  <!-- 编辑 -->
                  <a v-if="item.bulidType"
                    class="btn btn-icon btn-light btn-sm mx-3"
                    title="修改"
                    @click.prevent="role2initEdit(item.id)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg
                        title="修改"
                        src="media/svg/icons/Communication/Write.svg"
                      ></inline-svg>
                    </span>
                  </a>
                  <!-- 删除 -->
                  <a v-if="item.bulidType"
                    class="btn btn-icon btn-light btn-sm"
                    title="删除"
                    @click.prevent="role2del(item.id)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg
                        title="删除"
                        src="media/svg/icons/General/Trash.svg"
                      ></inline-svg>
                    </span>
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <!-- 翻页按钮 -->
        <div class="mt-3">
          <b-pagination
            limit="7"
            first-number
            last-number
            size="lg"
            align="right"
            v-model="search.pageNum"
            @change="role2page()"
            :per-page="pageSize"
            :total-rows="rows"
          ></b-pagination>
        </div>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->

    <!-- start:新增 -->
    <v-app  >
      <v-row justify="center">
        <v-dialog
          v-model="roleDialog.dialog"
          persistent
          scrollable
          max-width="600px"
        >
          <v-form
            ref="form"
            lazy-validation
          >
            <v-card>
              <!-- 表单头部 -->
              <v-toolbar dark color="primary" v-lcx-drag>
                <v-toolbar-title>{{ roleDialog.title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="roleDialog.dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>

              <v-card-text>
                <v-container style="padding-top: 10px">
                  <v-row>
                    <!-- 名称 -->
                    <v-col cols="12" >
                      <div class="d-flex justify-content-between mt-n5">
                        <label
                          class="font-size-h6 font-weight-bolder text-dark pt-5"
                          >名称*</label
                        >
                      </div>
                      <div>
                        <v-text-field
                          type="input"
                          name="name"
                          dense
                          v-model="currRole.name"
                          autocomplete="off"
                          outlined
                          :rules="[v => !!v || '不能为空']"
                        ></v-text-field>
                      </div>
                    </v-col>

                    <!-- 所属机构 -->
                    <v-col cols="12" >
                      <div class="d-flex justify-content-between mt-n5">
                        <label
                          class="font-size-h6 font-weight-bolder text-dark pt-5"
                          >所属机构*</label
                        >
                      </div>
                      <div>
                        <v-select
                          outlined
                          :rules="[v => !!v || '必选']"
                          name="orgzId"
                          item-text="name"
                          item-value="id"
                          v-model="currRole.orgzId"
                          :items="roleDialog.orgzList"
                          dense
                        ></v-select>
                      </div>
                    </v-col>

                  </v-row>
                </v-container>
                <span
                  class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                  id="kt_login_forgot"
                  >带 * 号的是必填项</span
                >
              </v-card-text>

              <!-- start:按钮区 -->
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn class="ma-2" color="success" type="submit" @click.prevent="role2save()"> 保存 </v-btn>

                <v-btn
                  color="blue-grey"
                  class="ma-2 white--text"
                  @click="roleDialog.dialog = false"
                >
                  关闭
                </v-btn>
              </v-card-actions>
              <!-- end:按钮区 -->
            </v-card>
          </v-form>
        </v-dialog>
      </v-row>
    </v-app>

    <!-- end:新增用户 -->

    <!-- 授权菜单 -->
    <v-app  >
      <v-row justify="center">
        <v-dialog
          v-model="resDialog.dialog"
          persistent
          scrollable
          max-width="600px"
        >
          <v-form
            ref="resform"
            lazy-validation
          >
            <v-card>
              <!-- 表单头部 -->
              <v-toolbar dark color="primary" v-lcx-drag>
                <v-toolbar-title>授权菜单</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="resDialog.dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>

              <v-card-text>
                <v-container style="padding-top: 10px">
                  <v-row>
                    <v-col cols="12"
                      v-for="(cat, catIndex) in resDialog.resList" 
                      :key="catIndex">
                      <div class="d-flex justify-content-between mt-n5">
                        <label
                          class="font-size-h6 font-weight-bolder text-dark pt-5"
                          >{{ cat.name }}</label
                        >
                        <span
                          class="text-primary font-size-h8 font-weight-bolder text-hover-primary pt-5"
                        ></span>
                      </div>
                      <div>
                        <v-row>
                          <v-col
                            v-for="res in cat.resourceList"
                            :key="res.id"
                            cols="12"
                            sm="4"
                            md="4"
                          >
                            <v-checkbox
                              color="success"
                              v-model="resDialog.resIdList"
                              :label="res.name"
                              :value="res.id"
                              hide-details
                              dense
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <!-- start:按钮区 -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" color="success" type="submit" @click.prevent="editRes()"> 保存 </v-btn>
                <v-btn
                  color="blue-grey"
                  class="ma-2 white--text"
                  @click="resDialog.dialog = false"
                >
                  关闭
                </v-btn>
              </v-card-actions>
              <!-- end:按钮区 -->
            </v-card>
          </v-form>
        </v-dialog>
      </v-row>
    </v-app>
  </div>
</template>

<style lang="scss">
.my-1 {
  padding-right: 10px;
}
// .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, 
// .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, 
// .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, 
// .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, 
// .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, 
// .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, 
// .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, 
// .col-md, .col-md-auto, .col-md-12, .col-md-11, 
// .col-md-10, .col-md-9, .col-md-8, .col-md-7, 
// .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, 
// .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, 
// .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, 
// .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, 
// .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, 
// .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
//     padding: 0px 12px !important;
// }
</style>

<script>

import Swal from "sweetalert2";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

import Role from "@/assets/js/pages/account/role.js";
import Account from "@/assets/js/pages/account/account.js";

import JwtService from "@/core/services/jwt.service";

export default {
  name: "role-list",

  data() {
    return {
      currRole: {
        id: "",
        name: "",
        orgzId: ""
      },
      search: {
        pageNum: 1,
        name: ""
      },
      rows: 100,
      pageSize: 5,
      roleList: [],
      roleDialog: {
        title: "新增角色",
        dialog: false,
        isAdd: false,
        orgzList: []
      },
      resDialog: {
        dialog: false,
        isAdd: false,
        resList: [],
        resIdList: []
      }
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  components: {},
  watch: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "角色管理", route: "" },
      { title: "角色列表" }
    ]);

    this.role2page();
    this.getOrgzList();

    this.resDialog.resList = JwtService.getResList();
    // this.resDialog.resList = [{"name": "lcx", "resourceList": [{"id": 1, "name": "111"},{"id": 2, "name": "222"},{"id": 3, "name": "333"}]}];
  },
  methods: {
    getOrgzList: function () {
      var that = this;
      Account.getOrgzList().then(({ data }) => {
        that.roleDialog.orgzList = data;
      });
    },

    role2page: function () {
      var that = this;

      Role.list(this.search).then(({ data }) => {
        that.roleList = data.list;

        that.pageSize = data.pageSize;
        that.search.pageNum = data.pageNum;
        that.rows = data.total;
      });
    },

    resetRole: function () {
      this.currRole.id = "";
      this.currRole.name = "";
      this.currRole.orgzId = "";
    },

    role2save: function () {
      if(!this.$refs.form.validate()){
            return false;
      }
      var that = this;
      Role.save(that.currRole).then(({ msg }) => {
        // 关闭模式窗口
        that.roleDialog.dialog = false;
        // 重新加载页面列表
        that.role2page();
        // 消息弹框
        window.Toast("success", msg);
        // 重置数据
        that.resetRole();
      });
    },

    role2initAdd: function () {
      var that = this;
      that.resetRole();

      that.roleDialog.dialog = true;
      that.roleDialog.title = "新增角色";
      that.roleDialog.isAdd = true;

      if (this.$refs.form && this.$refs.form.resetValidation) {
        this.$refs.form.resetValidation();
      }
    },

    role2initEdit: function (id) {
      var that = this;

      Role.findById(id).then(({ data }) => {
        // console.log(data);
        that.currRole = data;

        that.roleDialog.dialog = true;
        that.roleDialog.title = "修改角色";
        that.roleDialog.isAdd = false;

        if (this.$refs.form && this.$refs.form.resetValidation) {
          this.$refs.form.resetValidation();
        }
      });
    },

    role2del: function (id) {
      Swal.fire({
        title:
          '<span style="color: black;">删除操作不可恢复，是否确定删除？<span>',
        showCancelButton: true,
        confirmButtonText: `确定`,
        cancelButtonText: `取消`
      }).then(result => {
        if (result.isConfirmed) {
          Role.del(id).then(({ msg }) => {
            this.role2page();
            window.Toast("success", msg);
          });
        }
      });
    },

    editResInit: function(id) {
      Role.getResList(id).then(({ data }) => {
        this.resDialog.resIdList = data;
        // this.resDialog.resIdList = [1, 2];
        // console.log(data);
        this.resDialog.id = id;
        this.resDialog.dialog = true;
      });
    },

    editRes: function() {
      Role.saveResList(this.resDialog.id, this.resDialog.resIdList).then(({ msg }) => {
        this.resDialog.dialog = false;
        window.Toast("success", msg);
      });
    },
  }
};
</script>